<template>
  <div class="main">
    <div class="allSubmit">
      <van-button type="primary" size="small" block @click="allSubmit()"
        >通过</van-button
      >
      <van-button type="danger" size="small" block @click="cancelSubmit"
        >中止</van-button
      >
    </div>
    <div class="main_cont">
      <div class="checkBox" v-for="(items, index) in list" :key="index">
        <van-form>
          <van-cell-group inset>
            <div class="title">
              <van-checkbox v-model="items.isChek" icon-size="15px">{{
                items.name
              }}</van-checkbox>
            </div>
            <van-field name="radio" v-if="items.isChek">
              <template #input>
                <van-checkbox v-model="items.isImageCheck" icon-size="15px"
                  >是否需要拍照</van-checkbox
                >
              </template>
            </van-field>
            <van-field
              v-if="items.is_other == 1"
              type="textarea"
              autosize
              rows="1"
              placeholder="输入其他内容"
              v-model="items.otherName"
            ></van-field>
          </van-cell-group>
          <!-- <div class="button">
                        <van-button type="primary" size="small" native-type="submit" >提 交</van-button>
                    </div> -->
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import {onMounted,ref} from 'vue';
import {useRoute,useRouter} from 'vue-router';
import Http from '../../../api/http';
import {Notify,Dialog} from 'vant';
export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const list = ref([]);
        const getList = () =>{
            Http.get({
                method:'query',
                queryId:205
            },false,true,true)
            .then(res=>{
                list.value = res;
            })
            .catch(()=>{})
        }

        // 提交
        const allSubmit = () =>{
            var paramlist = [];
            list.value.forEach(elem=>{
                if(elem.isChek){
                    paramlist.push({name:elem.name+(!elem.otherName?'':elem.otherName),is_image:elem.isImageCheck == true?1:0,ptw_cetificate_id:route.params.id,types:elem.types})
                }
            });
            if(paramlist.length > 0){
                Dialog.confirm({
                    title: '标题',
                    message: '是否发布验收任务 ？',
                })
                .then(() => {
                    Http.post({
                        method:'query',
                        queryId:'206',
                        request:paramlist
                    })
                    .then(()=>{
                        Http.post({
                            method:'saveorupdate',
                            t:'PTW_CETIFICATE',
                            action:'success',
                            id:route.params.id
                        })
                        .then(()=>{
                             router.push('/ptwDetail/'+route.params.id);
                        })
                        .catch(()=>{})
                       
                    })
                    .catch(()=>{})
                })
                .catch(() => {});
                       

            }else{
                return Notify({ type: 'danger', message: `请选择验收项` });
            }
            console.log(paramlist)
        }
        // 中止
        const cancelSubmit = () =>{
            Dialog.confirm({
                title: '标题',
                message: '确认中止操作 ？',
            })
            .then(() => {
                Http.get({
                    method:'saveorupdate',
                    t:'PTW_CETIFICATE',
                    action:'close',
                    id:route.params.id
                },false,true)
                .then(()=>{
                    router.push('/ptwDetail/'+route.params.id)
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }


        onMounted(()=>{
            getList();
            console.log(route.params.id)
        });

        return{
            list,
            cancelSubmit,
            allSubmit
        }
    },
}
</script>


<style lang="less" scoped>
.main_cont{
    height: calc(100% - 40px);
    overflow-y: auto;
    width: 100%;
}
.checkBox{
    width: 95%;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px auto;
    .title{
        padding:10px;
        font-size: 15px;
        font-family: 'blod';
        border-bottom: 1px solid #eeeeee;
    }
    .van-radio-group--horizontal{
        width: calc(100% - 20px);
        padding: 0 10px;
        justify-content: space-between;
        .van-radio{
            border-bottom: 0;
        }
    }
    .button{
        width:100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.allSubmit{
    height: 35px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'blod';
    font-size: 16px;
}
</style>